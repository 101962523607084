<template>
  <div>
    <div v-if="!page" id="loader"></div>
    <div v-if="page" class="home animated fadeIn">
      <!-- OOO -->
      <OutOfOffice />

      <!-- INTRO -->
      <section class="intro">
          <header>
            <div class="container">
              <nav class="nav">
                <div class="nav__submenu">
                  <ul class="nav__submenu__list">
                    <li><a href="https://www.facebook.com/aquatimco" class="icon-before icon-big icon-social-facebook"></a></li>
                  </ul>
                  <ul class="nav__submenu__lang">
                    <li v-for="lang in supportedLanguages" :key="lang" v-on:click="changeLanguage(lang)" :class="{ '--active': isCurrentLanguage(lang) }">{{lang}}</li>
                  </ul>
                </div>
                <div class="nav__menu">
                  <img class="nav__menu__logo" src="@/assets/img/logo.png" alt="Timco logo">
                  <ul class="nav__menu__list" v-on:click="toggleMenu=false" :class="{'--toggle': toggleMenu}">
                    <li><a href="#">{{ $t('nav.welcome') }}</a></li>
                    <li><a href="#" v-scroll-to="'.services'">{{ $t('nav.services') }}</a></li>
                    <li><a href="#" v-scroll-to="'.references'">{{ $t('nav.references') }}</a></li>
                    <li><a href="#" v-scroll-to="'.contact'">{{ $t('nav.contact') }}</a></li>
                    <li>
                      <button class="btn btn--white icon-before icon-phone"><a href="tel:+32 16 79 46 60">{{ $t('nav.call_us') }}</a></button>
                    </li>
                  </ul>
                  <div class="nav__menu__burger" v-on:click="toggleMenu=!toggleMenu"><span class="icon-before" :class="toggleMenu ? 'icon-x' : 'icon-menu'"></span></div>
                </div>
              </nav>
            </div>
          </header>
          <div class="intro__container container">
            <div class="row">
                <div class="col-lg-6">
                  <h1 class="mb-xs-20 animated delay-1 fadeInUp">{{ page.title }}</h1>
                  <div class="mb-xs-40 animated delay-2 fadeInUp intro__description" v-html="page.description"></div>
                  <div>
                    <button class="btn btn--white icon-after icon-arrow-down animated delay-3 fadeInUp" v-scroll-to="'.services'">{{ $t('services.title') }}</button>
                  </div>
                </div>
            </div>
          </div>
          <div class="scroll" v-scroll-to="'.services'"></div>

          <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
            <defs>
            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g class="parallax">
            <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
            <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
            <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
            <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" />
            </g>
          </svg>
      </section>

      <!-- SERVICES -->
      <section class="services pt-xs-40 pt-lg-80 pb-xs-40 pb-lg-60 animated fadeInUp">
        <div class="container">
          <div class="row">
            <div class="col-lg-10 offset-lg-1">
              <h2 class="services__title mb-xs-20 mb-md-40">{{ page.services.title }}</h2>
              <div class="services__description t-center" v-html="page.services.description"></div>

              <swiper ref="serviceSwiper" :options="serviceSwiperOptions" class="services__swiper">
                <swiper-slide>
                  <div data-swiper-parallax-opacity="-100">
                    <div class="row">
                      <div class="offset-md-2 col-sm-6 col-md-4 mb-xs-30">
                        <div class="services__item" v-on:click="selectService('monoblock')">
                          <img src="@/assets/img/monoblock.png" alt="monoblock">
                          <h5>{{ $t('services.monoblock') }}</h5>
                          <a>{{ $t('common.more_info') }}</a>
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-4 mb-xs-30">
                        <div class="services__item" v-on:click="selectService('split')">
                          <img src="@/assets/img/split.png" alt="split">
                          <h5>{{ $t('services.split') }}</h5>
                          <a>{{ $t('common.more_info') }}</a>
                        </div>
                      </div>
                      <!-- <div class="col-sm-6 col-md-4 mb-xs-30">
                        <div class="services__item">
                          <a href="http://timco-air.be" target="_blank">
                            <img src="@/assets/img/airco.png" alt="airco">
                            <h5>Timco Air</h5>
                            <a class="icon-after icon-bold icon-external-link">{{ $t('common.more_info') }}</a>
                          </a>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div v-if="currentService==='monoblock'" class="services__item__description" data-swiper-parallax-opacity="-100">
                    <img src="@/assets/img/monoblock.png" class="mb-xs-10" width="140" alt="monoblock">
                    <h5 class="mb-xs-20">{{ $t('services.monoblock') }}</h5>
                    <div v-html="page.services.monoblock.description"></div>
                    <ul v-if="page.services.monoblock.links" class="services__item__links mt-xs-20 mlr-auto">
                      <li v-for="(link,index) in page.services.monoblock.links" :key="index">
                        <a class="icon-before icon-external-link" :href="link.url" target="_blank">{{ link.title }}</a>
                      </li>
                    </ul>
                  </div>
                  <div v-if="currentService==='split'" class="services__item__description" data-swiper-parallax-opacity="-100">
                    <img src="@/assets/img/split.png" class="mb-xs-10" width="140" alt="split">
                    <h5 class="mb-xs-20">{{ $t('services.split') }}</h5>
                    <div v-html="page.services.split.description"></div>
                    <ul v-if="page.services.split.links" class="services__item__links mt-xs-20 mlr-auto">
                      <li v-for="(link,index) in page.services.split.links" :key="index">
                        <a class="icon-before icon-external-link" :href="link.url" target="_blank">{{ link.title }}</a>
                      </li>
                    </ul>
                  </div>

                  <div v-on:click="serviceSwiper.slideTo(0)" class="services__swiper__back icon-before icon-arrow-left">{{ $t('common.all_services') }}</div>
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>
      </section>

      <!-- REFERENCES -->
      <References />

      <!-- REVIEWS -->
      <section class="reviews pt-xs-30 pb-xs-20 pt-lg-60 pb-lg-40">
        <div class="container">
          <!-- <div v-if="reviews.length > 0" class="row mb-xs-20 mb-lg-50">
            <div class="col-lg-8 offset-lg-2">
              <swiper ref="reviewSwiper" :options="reviewSwiperOptions">
                <swiper-slide v-for="(review,index) in reviews" :key="index">
                  <div data-swiper-parallax-opacity="-10">
                    <div class="review__name">{{ review.title }}</div>
                    <ul class="review__stars">
                      <li v-for="star in 5" :key="star" :class="'animated bounceIn delay-'+star"><img src="@/assets/img/star.png" width="35" alt="star rating"></li>
                    </ul>
                    <p class="review__comment">“ {{ review.comment }} ”</p>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
          </div> -->

          <!-- PARTNERS -->
          <Partners />
        </div>
      </section>

      <!-- CONTACT -->
      <section class="contact pt-xs-40 pt-lg-120 pb-xs-60 pb-lg-100">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-4 mb-xs-40 mb-lg-0">
              <h3>{{ $t('contact.title') }}</h3>
              <p><a href="https://www.google.com/maps/place/Timco+BV/@50.9500949,4.7108966,17z/data=!3m1!4b1!4m5!3m4!1s0x47c15e653fe9b3bd:0x419562723d364a67!8m2!3d50.9500826!4d4.7130525" target="_blank">Leuvensebaan 6, 3110 Rotselaar</a></p>
              <p>KBO 0675.663.495</p>

              <ul class="contact__list mt-xs-40">
                <li><span class="icon icon-before icon-mail"></span><a href="mailto:info@aqua-timco.be">info@aqua-timco.be</a></li>
                <li><span class="icon icon-before icon-phone"></span><a href="tel:+32 16 79 46 60">+32 16 79 46 60</a></li>
              </ul>
            </div>
            <div class="col-12 col-lg-8">
              <form ref="form" @submit.prevent="sendForm()" class="row">
                <div class="col-12 col-md-6 mb-xs-20">
                  <label class="input-text">
                    <span class="input-text__lbl">{{ $t('contact.form.name') }}</span>
                    <input type="text" name="fullname" placeholder="Tim Co" v-model="fullname" required>
                  </label>
                </div>
                <div class="col-12 col-md-6 mb-xs-20">
                  <label class="input-text">
                    <span class="input-text__lbl">{{ $t('contact.form.phone') }}</span>
                    <input type="tel" name="phone" placeholder="+32 494 68 75 58" v-model="phone" v-mask="['+## ### ## ## ##', '+## ### ## ##']" required>
                  </label>
                </div>
                <div class="col-12 mb-xs-20">
                  <label class="input-text">
                    <span class="input-text__lbl">{{ $t('contact.form.email') }}</span>
                    <input type="email" name="email" placeholder="tim@example.be" v-model="email" required>
                  </label>
                </div>
                <div class="col-12 mb-xs-20">
                  <label class="input-text">
                    <span class="input-text__lbl">{{ $t('contact.form.street') }}</span>
                    <input type="text" name="street" placeholder="Leuvensebaan 6" v-model="street" required>
                  </label>
                </div>
                <div class="col-12 col-md-6 mb-xs-20">
                  <label class="input-text">
                    <span class="input-text__lbl">{{ $t('contact.form.city') }}</span>
                    <input type="text" name="city" placeholder="Rotselaar" v-model="city" required>
                  </label>
                </div>
                <div class="col-12 col-md-6 mb-xs-20">
                  <label class="input-text">
                    <span class="input-text__lbl">{{ $t('contact.form.zipcode') }}</span>
                    <input type="tel" name="zipcode" placeholder="3110" v-model="zipcode" v-mask="'####'" required>
                  </label>
                </div>
                <div class="col-12 mb-xs-20">
                  <label class="input-text">
                    <span class="input-text__lbl">{{ $t('contact.form.msg') }}</span>
                    <textarea name="message" rows="8" placeholder="Hi there..." v-model="message" required></textarea>
                  </label>

                  <div class="mt-xs-20">
                    <label class="input-checkbox">
                      <input type="checkbox" name="optin" v-model="optin">
                      <div class="input-checkbox__btn"></div>
                      <div class="input-checkbox__lbl" v-html="$t('contact.form.optin')"></div>
                    </label>
                  </div>

                  <div class="mt-xs-30">
                    <p class="t-success icon-before icon-check" v-if="success && !sending" v-html="$t('contact.form.success')"></p>
                    <p class="t-error icon-before icon-alert-circle" v-if="error && !sending" v-html="$t('contact.form.error')"></p>
                    <p class="t-error icon-before icon-alert-circle" v-if="validationError && !sending" v-html="$t('contact.form.validation')"></p>
                  </div>

                  <template v-if="!success">
                    <vue-recaptcha class="recaptcha" @verify="onVerify" @expired="onExpired" sitekey="6Le7tI4bAAAAAIDj-aFTaie8kfTOVN9VYdqjs4aj"></vue-recaptcha>

                    <div class="mt-xs-40">
                      <button type="submit" class="btn btn--main icon-after icon-send" :class="{'btn--loading': sending}" :disabled="sending || !fullname || !street || !city || !zipcode || !email || !phone || !message || !optin || !recaptchaVerified">{{ sending ? $t('contact.form.sending')+'...' : $t('contact.form.send') }}</button>
                    </div>
                  </template>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <section class="footer">
        <footer class="container">
          <div>
            Leuvensebaan 6, 3110 Rotselaar | <a class="--underline" href="mailto:info@aqua-timco.be">info@aqua-timco.be</a>
          </div>
          <div>
            &copy; Powered by <a href="https://netvise.be/" target="_blank">Netvise</a>
          </div>
        </footer>
      </section>

    </div>
  </div>
</template>

<script>
import { Trans } from '@/plugins/Translation'
import VueRecaptcha from 'vue-recaptcha';
import OutOfOffice from '../components/OutOfOffice.vue';
import References from '../components/References.vue';
import Partners from '../components/Partners.vue';

export default {
  name: 'Home',
  components: { VueRecaptcha, OutOfOffice, References, Partners },
  data() {
    return {
      toggleMenu: false,
      page: null,
      reviews: null,
      ffullname: "",
      street: "",
      city: "",
      zipcode: "",
      email: "",
      phone: "",
      message: "",
      optin: false,
      success: false,
      error: false,
      validationError: false,
      recaptchaVerified: false,
      sending: false,
      formApiUrl: process.env.VUE_APP_FORM_API_URL+"/wp-json/contact-form-7/v1/contact-forms/36/feedback",
      currentService: 'monoblock',
      reviewSwiperOptions: {
        allowTouchMove: false,
        loop: true,
        parallax: true,
        autoplay: {
          delay: 5000,
        }
      },
      serviceSwiperOptions: {
        allowTouchMove: false,
        autoHeight: true,
        parallax: true,
        spaceBetween: 30
      }
    }
  },
  computed: {
      reviewSwiper() {
        return this.$refs.reviewSwiper.$swiper
      },
      serviceSwiper() {
        return this.$refs.serviceSwiper.$swiper
      },
      supportedLanguages () {
        return Trans.supportedLanguages
      },
      currentLanguage () {
        return Trans.currentLanguage
      }
  },
  mounted: function(){
    this.$api.getPageBySlug('home').then(data => {
      this.page = data.acf;
    });

    this.$api.getReviews().then(data => {
      this.reviews = data;
    });
  },
  methods: {
    onVerify() {
      this.recaptchaVerified = true;
    },
    onExpired() {
      this.recaptchaVerified = false;
    },
    changeLanguage (lang) {
      this.toggle = false;
      const to = this.$router.resolve({ params: { lang } })
      return Trans.changeLanguage(lang).then(() => {
        this.$router.push(to.location)
        location.reload();
      })
    },
    isCurrentLanguage (lang) {
      return lang === this.currentLanguage
    },
    selectService(name) {
      this.$scrollTo('.services');
      this.currentService = name;
      setTimeout(() => {
        this.serviceSwiper.slideTo(1);
      },10)
    },
    sendForm() {
      this.sending = true;
      const data = new FormData(this.$refs.form);
      this.axios.post(this.formApiUrl, data).then((response) => {
        this.sending = false;
        const status = response.data.status;
        switch(status) {
          case 'mail_sent':
            this.success = true;
            this.validationError = false;
            this.error = false;
            this.fullname = "";
            this.street = "";
            this.city = "";
            this.zipcode = "";
            this.email = "";
            this.phone = "";
            this.message = "";
            this.optin = false;
            break;

          default:
            this.validationError = true;
            this.success = false;
            this.error = false;
        }
      }).catch(error => {
          this.sending = false;
          console.log(error.response.data.error);
          this.error = true;
          this.validationError = false;
          this.success = false;
      })
    }
  }
}
</script>
