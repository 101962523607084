<template>
  <div v-if="oooEnabled && !hidden" id="ooo-banner">
    <div>
      <span class="icon-before icon-sun icon-bold"></span>{{ $t('common.out_of_office') }} <u>{{ oooEndDate }}</u>.
    </div>
    <span class="banner-close icon-before icon-x" v-on:click="hidden = true"></span>
  </div>
</template>

<script>
export default {
  name: 'OutOfOffice',
  data() {
    return {
      oooEnabled: false,
      oooEndDate: null,
      hidden: false
    }
  },
  mounted: function(){
    this.$api.getOptions().then(data => {
        const today = new Date().setHours(0,0,0,0);
        const startDate = new Date(data.start_date);
        const endDate = new Date(data.end_date);
        if(today >= startDate && today <= endDate){
          this.oooEnabled = true;
          this.oooEndDate = data.end_date.split('/').reverse().join('/');
        }
      });
  },
}
</script>
