<template>
  <swiper class="partners" ref="partnerSwiper" :options="partnerSwiperOptions">
    <swiper-slide>
      <a class="partners__item" href="http://www.fujitsu-airco.be/" target="_blank">
        <img src="@/assets/img/partners/Fujitsu.png" alt="Fujitsu">
      </a>
    </swiper-slide>
    <swiper-slide>
      <a class="partners__item" href="https://www.cairox.be/NL/" target="_blank">
        <img src="@/assets/img/partners/Cairox.png" alt="Cairox">
      </a>
    </swiper-slide>
    <swiper-slide>
      <a class="partners__item" href="https://www.brink-comfort.be/nl-BE/content/zorgeloos-genieten-van-een-comfortabele-thuis/24/" target="_blank">
        <img src="@/assets/img/partners/Brink.png" alt="Brink">
      </a>
    </swiper-slide>
    <swiper-slide>
      <a class="partners__item" href="https://www.duco.eu/be_nl/home" target="_blank">
        <img src="@/assets/img/partners/Duco.png" alt="Duco">
      </a>
    </swiper-slide>
    <swiper-slide>
      <a class="partners__item" href="http://www.bticino.com/" target="_blank">
        <img src="@/assets/img/partners/Bticino.png" alt="Bticino">
      </a>
    </swiper-slide>
    <swiper-slide>
      <a class="partners__item" href="http://www.cebeolightstudio.be/" target="_blank">
        <img src="@/assets/img/partners/Cebeo.png" alt="Cebeo">
      </a>
    </swiper-slide>
    <swiper-slide>
      <a class="partners__item" href="http://www.demagro.be/" target="_blank">
        <img src="@/assets/img/partners/Demagro.png" alt="Demagro">
      </a>
    </swiper-slide>
  </swiper>
</template>

<script>
export default {
  name: 'Partners',
  data() {
    return {
      partnerSwiperOptions: {
        loop: true,
        direction: 'horizontal',
        allowTouchMove: false,
        slidesPerView: 5,
        spaceBetween: 30,
        autoplay: true,
        breakpoints: {
          320: {
            slidesPerView: 2
          },
          500: {
            slidesPerView: 3
          },
          768: {
            slidesPerView: 4
          },
          991: {
            slidesPerView: 5
          }
        }
      }
    }
  },
  methods: {
    
  }
}
</script>
