<template>
  <section class="references pb-xs-40 pb-lg-80">
      <div class="container">
        <h2 class="mb-xs-40 mb-lg-40">{{ $t('references.title') }}</h2>

        <ul class="references__filter mb-xs-50 mt-lg-60 mb-lg-60">
          <li v-on:click="selectReference(ref.id)" v-for="ref in references" :key="ref.id" :class="{'--selected': ref.id === selectedReferenceId}">{{ ref.title }}</li>
        </ul>

        <div v-if="!selectedReference || !selectedReference.galleryLoaded" class="references__loader mt-xs-20 mb-xs-20"></div>

        <template v-if="references.length > 0 && selectedReference !== null && selectedReference.galleryLoaded">
          <masonry transition-duration="1s" :cols="{default: 4, 1000: 3, 700: 2, 400: 1}" :gutter="{default: '30px', 700: '15px'}" class="references__pictures">
            <template v-for="(picture,index) in selectedReference.gallery">
              <div v-if="index < 12" class="references__pictures__item animated fadeInUp" v-on:click="galleryIndex=index" :key="index">
                <img :src="picture.url">
              </div>
            </template>
          </masonry>

          <LightGallery
            v-if="galleryIndex !== null"
            :images="selectedReference.gallery"
            :index="galleryIndex"
            :interfaceColor="'#00AEA9'"
            :disable-scroll="true"
            @slide="galleryIndex=$event.index"
            @close="galleryIndex = null"
          />

          <div v-if="galleryIndex >= 0 && galleryIndex !== null && selectedReference.gallery[galleryIndex].model && selectedReference.gallery[galleryIndex].location" class="references__info animated fadeInUpCenter">
            <span class="references__info__model">{{ selectedReference.gallery[galleryIndex].model }}</span>
            <span class="references__info__location"><span class="icon-before icon-map-pin"></span>{{ selectedReference.gallery[galleryIndex].location }}</span>
          </div>
        </template>
      </div>
  </section>
</template>

<script>
import { LightGallery } from 'vue-light-gallery';

export default {
  name: 'References',
  components: {
    LightGallery,
  },
  data() {
    return {
      galleryIndex: null,
      references: [],
      selectedReferenceId: null,
      selectedReference: null,
    }
  },
  mounted: function(){
    this.getReferences()
  },
  methods: {
    getReferences() {
      this.$api.getReferences().then(data => {
        this.selectReference(data[0].id)
        this.references = data
      });
    },
    selectReference(id) {
      if(id !== undefined){
        this.selectedReference = null;
        this.selectedReferenceId = id;

        this.$api.getReference(id).then(data => {
          this.selectedReference = data
          this.$imagePreload(this.selectedReference.gallery.map(img => img.url)).then(r => {
            this.selectedReference.galleryLoaded = r;
          })
        });
      }
    }
  }
}
</script>
